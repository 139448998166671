export const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #e1e1e1',
  borderRadius: '8px',
  maxHeight: '95%',
  overflowY: 'auto',
  minWidth: '65%',
  boxShadow: 24,
  p: 4,
};

export const error = {
  color: 'red',
  position: 'relative',
};
