import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';

const RoleSwitch = ({ setEndpoint }: { setEndpoint: (ep: string) => void }) => {
  const [isAdmin, setIsAdmin] = useState(false);

  const handleClick = () => {
    setIsAdmin((prev) => !prev);
  };

  useEffect(() => {
    switch (isAdmin) {
      case true:
        setEndpoint('AngeboteAuth/get');
        break;
      case false:
        setEndpoint('AngeboteAuth/getuser');
        break;
    }
  }, [isAdmin, setEndpoint]);

  return (
    <Button onClick={handleClick}>
      {!isAdmin && <>Wechsel zur Admin Ansicht</>}
      {isAdmin && <>Wechsel zur Nutzer Ansicht</>}
    </Button>
  );
};

export default RoleSwitch;
