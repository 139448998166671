import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

const list_style = {
  mt: '8em',
  '& a': {
    textDecoration: 'none',
    color: '#000',
    fontWeight: '700',
    fontSize: '1em',
  },
};

type Props = {
  drawerWidth: number;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
};
export default function DrawerLinks({
  drawerWidth,
  mobileOpen,
  handleDrawerToggle,
}: Props) {
  let container;

  useEffect(() => {
    container = window !== undefined ? () => window.document.body : undefined;
  }, []);

  return (
    <Box
      component='nav'
      aria-label='Persönliches Menü'
      sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
    >
      {/* mobile */}
      <Drawer
        container={container}
        variant='temporary'
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }} // Better open performance on mobile.
        sx={{ display: { xs: 'block', md: 'none' } }}
      >
        <Box sx={{ p: '0.6em 2em' }}>
          <h2>Menü</h2>
          <MenItems />
        </Box>
      </Drawer>

      {/* desktop */}
      <Drawer
        variant='permanent'
        open
        sx={{ display: { xs: 'none', md: 'block' } }}
      >
        <Box sx={{ p: '0.6em 2em' }}>
          {/* <h2>Dashboard</h2> */}
          <MenItems />
        </Box>
      </Drawer>
    </Box>
  );
}

const MenItems = () => {
  const menu_items = [
    { name: 'Meine Stammdaten', link: '/stammdaten' },
    /* { name: 'Einstellungen', link: '/einstellungen' }, */
    { name: 'Hilfe & Kontakt', link: '/hilfe' },
    /* { name: 'Datenschutz', link: '/datenschutz' }, */
  ];
  return (
    <List sx={list_style}>
      {menu_items.map((el: typeof menu_items[0]) => (
        <NavLink
          key={el.name}
          activeStyle={{ color: '#4499a0' }}
          to={`${el.link}`}
        >
          <ListItem sx={{ pl: 0, pr: 0 }}>{el.name}</ListItem>
        </NavLink>
      ))}
    </List>
  );
};
