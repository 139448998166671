// Link zum Mut Atlas

export const getMutAtlasLink = (
  lat: number | undefined,
  long: number | undefined
) => {
  if (typeof long !== 'undefined' && typeof lat !== 'undefined') {
    return `https://www.mut-atlas.de/home?center=${lat}%2C${long}&zoom=13`;
  } else {
    return 'https://www.mut-atlas.de';
  }
};
