import { postData } from '@components/_api';
import { Box1 } from '@components/dashboard-ma/MAEinrichtung/MAEinrichtung-Styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Angebot } from '../../../../interfaces';
import CreateZustaendigenModal from './components/CreateZustaendigenModal';

export type PersonDataType = {
  angebotApId: number;
  fileMakerRecordId: number;
  vorname: string;
  nachname: string;
  email: string;
  telefon: string;
  handy: string;
  funktion: string;
  lfNrAdress: string;
  lfNrAngebote: string;
  lfNrAdressAngebot: string;
};

export default function Zustaendige({
  handleChange,
  mode,
  token,
  data,
}: {
  handleChange: (persons: PersonDataType[]) => void;
  mode: 'edit' | 'create';
  data?: Angebot;
  token: string;
}) {
  const [zustandigeModal, setZustandigeModal] = React.useState<{
    open: boolean;
    angebotApId: number | null;
  }>({
    open: false,
    angebotApId: null,
  });
  const { angebotApId, open } = zustandigeModal;

  const [openTooltip, setOpenTooltip] = React.useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    let ignore = false;
    setIsLoading(true);

    if (!data?.autoLfNr || mode === 'create') {
      setIsLoading(false);
      setError(undefined);
      return;
    }

    postData({
      endpoint: `angeboteauth/angebotaps`,
      token,
      apiversion: 'v2',
      json: {
        search: '',
        autoLfNr: data.autoLfNr,
        skip: 0,
        take: 50,
      },
    })
      .then((res) => {
        if (res.statusText !== 'OK') {
          return setError('Ein Fehler ist aufgetreten');
        }
        return res.data;
      })
      .then((data) => {
        if (!ignore) {
          handleChange(data);
          setError(undefined);
        }
      })
      .catch((err) => {
        if (!ignore) {
          setError(err);
          handleChange([]);
        }
      })
      .finally(() => {
        if (!ignore) {
          setIsLoading(false);
        }
      });

    return () => {
      ignore = true;
    };
  }, [data?.autoLfNr, token, mode]);

  const persons = data?.angebotePos;

  const handleDeletePerson = (fileMakerRecordId: number) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL_V2}AngeboteAuth/deleteangebotap/${fileMakerRecordId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.statusText !== 'OK') {
          setError('Ein Fehler ist aufgetreten');
        }
        return res.data;
      })
      .then(() => {
        handleChange(
          persons
            ? persons.filter((p) => p.fileMakerRecordId !== fileMakerRecordId)
            : []
        );
        setError(undefined);
      });
  };

  return (
    <Box sx={{ mb: '2em' }}>
      <Box1>
        <Typography variant='h1'>
          Zuständige Personen{' '}
          <Tooltip
            title={
              <Typography>
                Bei Psychiatrien, Krankenhäusern und anderen stationären
                Angeboten bitte keine konkreten Behandler eingeben, sondern
                lieber Zentralen
              </Typography>
            }
            open={openTooltip}
            onOpen={() => setOpenTooltip(true)}
            onClose={() => setOpenTooltip(false)}
          >
            <IconButton onClick={() => setOpenTooltip(true)}>
              <InfoOutlinedIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '1.5em',
            mb: '2em',
            mt: '2em',
          }}
        >
          {isLoading ? (
            <Typography>Loading...</Typography>
          ) : error ? (
            <Typography>{error}</Typography>
          ) : (
            persons?.map((currentPerson, i) => {
              const {
                funktion,
                vorname,
                nachname,
                telefon,
                handy,
                email,
                fileMakerRecordId,
              } = currentPerson;
              return (
                <Box
                  key={i}
                  display='flex'
                  flexDirection='column'
                  justifyContent='space-between'
                  boxShadow={1}
                  sx={{
                    backgroundColor: '#fff',
                    borderRadius: '5px',
                    // border: '1px solid #f1f1f1 ',
                    padding: '1rem',
                    '& div': { color: 'inherit', fontWeight: 'normal' },
                    '& div:last-of-type': { mb: '1em' },
                    '& .mail': { mt: '.3em' },
                    '& address': {
                      fontStyle: 'inherit',
                      fontWeight: 'normal',
                      mt: '.3em',
                    },
                  }}
                >
                  <Box>
                    <div>
                      {vorname} {nachname}
                    </div>
                    <div className={'function'}>
                      <strong>Funktion</strong>: {funktion}
                    </div>
                    <div className={'mail'}>{email}</div>
                    <div>Tel: {telefon}</div>
                    <div>Mobil: {handy}</div>
                  </Box>
                  <Box display='flex' gap={2} alignSelf='end'>
                    <Button
                      onClick={() => {
                        mode === 'edit'
                          ? handleDeletePerson(fileMakerRecordId)
                          : handleChange(
                              persons?.filter((_, index) => index !== i)
                            );
                      }}
                      centerRipple={true}
                      color='error'
                      variant='outlined'
                    >
                      Löschen
                    </Button>
                  </Box>
                </Box>
              );
            })
          )}
        </Box>

        <Box>
          <CreateZustaendigenModal
            currentPerson={
              persons
                ? persons.find((p) => p.angebotApId === angebotApId)
                : undefined
            }
            open={open}
            handleClose={() =>
              setZustandigeModal({ angebotApId: null, open: false })
            }
            handlePersons={(person) => {
              handleChange(person);
            }}
            token={token}
            mode={mode}
            data={data}
          />

          <Button
            onClick={() =>
              setZustandigeModal({ angebotApId: null, open: true })
            }
          >
            Zuständige Person hinzufügen
          </Button>
        </Box>
      </Box1>
    </Box>
  );
}
