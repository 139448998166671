import { TInitTraegerData } from '@components/dashboard-ma/components/Traeger/components/CreateTraegerModal';
import React, { useState } from 'react';

interface TraegerCTX {
  data: TInitTraegerData;
  setData: React.Dispatch<React.SetStateAction<TInitTraegerData>>;
}

const TraegerContext = React.createContext<TraegerCTX>({} as TraegerCTX);

export const useTraegerContext = () => React.useContext(TraegerContext);

export const TraegerProvider = ({ children }: { children: JSX.Element }) => {
  const [data, setData] = useState<TInitTraegerData>({} as TInitTraegerData);

  return (
    <TraegerContext.Provider
      value={{
        data: data,
        setData: setData,
      }}
    >
      {children}
    </TraegerContext.Provider>
  );
};
