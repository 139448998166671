import { getData } from '@components/_api';
import React, {
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import BackBtn from '../BackBtn';
import { empty_data } from './empty_einrichtung';

import {
  EditableAngebotInputObject,
  OpeningHours,
} from '@components/../interfaces';
import SpeichernBtn from '@components/SpeichernBtn';

import MANeueEinrichtungTop from './components/MANeueEinrichtungTop';
import { topFormFields } from './components/topFormFields';

import MultipleSelect from '@components/dashboard-ma/MAEinrichtung/MultipleSelect';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  SelectChangeEvent,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
  Box1,
  Box2,
} from '@components/dashboard-ma/MAEinrichtung/MAEinrichtung-Styles';
import Zustaendige, {
  PersonDataType,
} from '@components/dashboard-ma/components/zustaendige/Zustaendige';

import { UnauthorizedDialog } from '@components/UnauthorizedDialog';
import { Header } from '@components/dashboard-ma/components/Header';
import { Form, Formik } from 'formik';
import { useSnackbarContext } from '../../context/SnackBarContext';
import { TraegerProvider } from '../../context/TraegerContext';
import useGetCategories from '../../hooks/useGetCategories';
import { isObjectKey, isUUID } from '../../utils/lib';
import { validation } from '../../utils/topEinrichtungValidation';
import {
  KAT_NICE_NAMES,
  kats_translations,
} from './MAEinrichtung/filter-categories';
import OpeningHoursPicker from './components/OpeningHoursPicker';
import TraegerWrap from './components/Traeger/TraegerWrap';

export interface TransformedOpeningHours {
  dayOfWeek: number;
  openings: OpeningHours[];
}

type KatType = {
  [key: string]: boolean;
};

export type FormValues = Partial<EditableAngebotInputObject> &
  Partial<{
    postfachPLZ: string;
    isWebsiteUnavailable: boolean;
    kats: Record<string, KatType | string[]>;
  }>;

export default function MAEinrichtung({ token }: { token: string }) {
  const { guid }: { guid: string } = useParams();
  const snackbarctx = useSnackbarContext();
  const formRef = useRef<HTMLFormElement>(null);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<EditableAngebotInputObject>(empty_data);
  const [openTooltip, setOpenTooltip] = React.useState(false);
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  const isAlwaysOpen = data['einsGeoeffnetDurchgehend'];
  const isOpenAppointment = data['einsGeoeffnetNachVereinbarung'];

  const { kats, katWasUndWasDetails } = useGetCategories();

  useEffect(() => {
    if (guid && !data?.guid) {
      setLoading(true);
      // only set the data from API if it is empty
      getData({
        endpoint: 'AngeboteAuth/angebotuser/' + guid,
        token,
        apiversion: 'v2',
      })
        .then((resp) => {
          if (resp.status === 200) {
            const filledHours = Array(7)
              .fill({})
              .map((_, index) => {
                const found = resp.data?.openingHours?.find(
                  (item: OpeningHours) => item.dayOfWeek === index + 1
                );
                return (
                  found || {
                    dayOfWeek: index + 1,
                    open1: null,
                    close1: null,
                    open2: null,
                    close2: null,
                  }
                );
              });
            setData({ ...resp.data, openingHours: filledHours });
          }
        })
        .catch((e) => {
          if (e.message === 'Request failed with status code 403') {
            setIsUnauthorized(true);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [guid, data?.guid]);

  const updateData = (feld: { [name: string]: string }) => {
    setData({ ...data, ...feld });
  };

  const handleZustaendigerChange = (persons: PersonDataType[]): void => {
    setData({
      ...data,
      angebotePos: persons,
    });
  };

  const handleTopChange = (
    ev: React.ChangeEvent | SelectChangeEvent<string>,
    name: string
  ): void => {
    // @ts-ignore
    let value = ev.target.value.toString();

    if (name === 'plz') value = value.trim();

    updateData({
      [name]: value,
    });
  };

  // get maximum checkbox count per category from localStorage
  const multifieldcounts = localStorage.getItem('multifieldcounts');
  const checkBoxFieldCounts: Record<string, number> = multifieldcounts
    ? JSON.parse(multifieldcounts)
    : {};

  // create initial opening hours data for formik
  const formikInitialValues = () => {
    const result: FormValues = {};
    topFormFields.forEach(({ name }) => {
      const value = data[name as keyof typeof data];

      if (value !== undefined) {
        // @ts-ignore
        result[name] = value;
      }
    });
    return {
      ...result,
      isWebsiteUnavailable: data['isWebsiteUnavailable'] ?? false,
      neueKlientenApi: data['neueKlientenApi'],
      einsGeoeffnetDurchgehend: isAlwaysOpen,
      einsKeinTraeger: data['einsKeinTraeger'],
      einsDeutschlandweit: data['einsDeutschlandweit'],
      einsGeoeffnetNachVereinbarung: isOpenAppointment,
      validierung: data['validierung'],
    };
  };

  const katWasDetailsInitialValues = [
    // return unique values
    ...new Set(
      data.mfAngebotMultifieldValues
        .filter(
          ({ fmMultiField }) =>
            fmMultiField.name.toLowerCase() === 'kat_wasdetail'
        )
        .map((item) => item.fmMultiFieldValue.value)
    ),
  ];

  const checkBoxKatsInitialValues = () => {
    const checkboxKats: Record<string, Record<string, boolean>> = {};

    // Iterate through keys in kat
    for (const key in kats) {
      if (isObjectKey(kats, key) && key !== 'Kat_WasDetail') {
        const checkboxes = kats[key];
        const checkboxObj: Record<string, boolean> = {};

        for (let index = 0; index < checkboxes.length; index++) {
          const element = checkboxes[index];
          const datahasFieldValue =
            data.mfAngebotMultifieldValues &&
            data.mfAngebotMultifieldValues.some(
              (item) =>
                item.fmMultiFieldValue.value.toLowerCase() ===
                element.toLowerCase()
            );

          checkboxObj[element] = datahasFieldValue ? true : false;
        }

        checkboxKats[kats_translations[key]] = checkboxObj;
      }
    }
    return checkboxKats;
  };

  const formikValues: FormValues = {
    ...formikInitialValues(),
    openingHours: data.openingHours || [],
    kats: {
      kat_WasDetail: katWasDetailsInitialValues,
      ...checkBoxKatsInitialValues(),
    },
  };

  const scrollToElement = (name: string) => {
    const element = formRef.current?.elements.namedItem(
      name
    ) as HTMLElement | null;
    if (element) {
      const headerOffset = 75;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;

      window.scrollTo({ behavior: 'smooth', top: offsetPosition });
    }
  };

  // validate the fields if they have not been touched yet
  const handleValidateFields = async (formik: any) => {
    try {
      const valid = await formik.validateForm();

      const errorKeys = Object.keys(valid) || [];
      const firstErrorIndex = errorKeys.findIndex(
        (key) => valid[key as keyof FormValues] !== undefined
      );

      // Check if fields are inValid and set them to touched to show errors
      errorKeys.forEach((key) => {
        if (valid[key as keyof FormValues] !== undefined) {
          formik.setFieldTouched(key, true, true);

          if (key === 'openingHours') {
            const { dayOfWeek, ...firstOpeningError } =
              valid['openingHours']?.[0] || {};

            const day = dayOfWeek - 1;
            const firstElement = Object.keys(firstOpeningError || {})?.[0];

            scrollToElement(`openingHours.${day}.${firstElement}`);

            //set all opening hours to touched
            Object.keys(firstOpeningError || {}).forEach((key) => {
              formik.setFieldTouched(`openingHours.${day}.${key}`, true, true);
            });
          }

          // Scroll to first error
          if (firstErrorIndex !== -1) {
            const firstErrorKey = errorKeys[firstErrorIndex];
            scrollToElement(firstErrorKey);
          }
        }
      });

      return Object.keys(valid).length === 0;
    } catch (errors) {
      return false;
    }
  };

  // Copy opening hours from Monday to other days except Saturday and Sunday
  const handleCopyFromMonday = useCallback(
    async (
      setValues: (values: SetStateAction<typeof formikValues>) => void
    ) => {
      setValues((prevValues) => {
        const hours = prevValues.openingHours || [];
        const mondayTimes = hours?.find(({ dayOfWeek }) => dayOfWeek === 1);
        const saturdayAndSunday = hours?.filter(
          ({ dayOfWeek }) => dayOfWeek === 6 || dayOfWeek === 7
        );

        const { open1, close1, open2, close2 } =
          mondayTimes || ({} as OpeningHours);

        const clonedHours =
          hours.length > 0
            ? Array.from({ length: 5 }).map((_, dayIndex) => {
                return {
                  dayOfWeek: dayIndex + 1,
                  open1,
                  close1,
                  open2,
                  close2,
                };
              })
            : [];

        return {
          ...prevValues,
          openingHours: [...clonedHours, ...saturdayAndSunday],
        };
      });
    },
    []
  );

  if (loading) {
    return (
      <Box>
        <LinearProgress color='success' />
      </Box>
    );
  }

  return (
    <TraegerProvider>
      <>
        <Header data={data} guid={guid} />
        <Formik<FormValues>
          initialValues={formikValues}
          onSubmit={() => {}}
          enableReinitialize
          validate={(values) => validation(values)}
          validateOnChange={false}
        >
          {(formik) => {
            const {
              values,
              setValues,
              handleChange,
              setFieldValue,
              validateForm,
            } = formik;

            const katsValues = values['kats'];
            const wasDetailsValues = katsValues?.kat_WasDetail as string[];

            const katWasDetailsSelect = () => {
              let details: string[] = [];
              Object.keys(katWasUndWasDetails).forEach((keyKat) => {
                if (
                  katsValues?.kat_Was &&
                  katsValues?.kat_Was[keyKat as keyof FormValues['kats']]
                ) {
                  details = details.concat(katWasUndWasDetails[keyKat]);
                }
              });

              return details;
            };

            const categoriesSeletected: Array<{
              fmMultiField: { name: string };
              fmMultiFieldValue: { value: string };
            }> = [];

            //Push checkboxes and construct api payload
            for (const [key, value] of Object.entries(katsValues || {})) {
              for (const [subKey, subValue] of Object.entries(value)) {
                if (subValue && key !== 'kat_WasDetail') {
                  categoriesSeletected.push({
                    fmMultiField: {
                      name: key,
                    },
                    fmMultiFieldValue: {
                      value: subKey,
                    },
                  });
                }
              }
            }

            // Also push values from KatWasdetails from select input
            for (let index = 0; index < wasDetailsValues.length; index++) {
              categoriesSeletected.push({
                fmMultiField: {
                  name: 'kat_WasDetail',
                },
                fmMultiFieldValue: {
                  value: wasDetailsValues[index],
                },
              });
            }

            // Creating a new values object without the 'kats' key
            const { kats, ...newValues } = values;
            const allowedCheckboxes: Record<string, string> = {};

            const openingHours = values.openingHours
              ? (values.openingHours
                  .map((opening) => {
                    const { open1, close1, open2, close2, ...rest } =
                      opening || ({} as OpeningHours);

                    //only return the values if they are valid
                    const hours = {
                      ...(open1 && open1 !== 'Invalid Date' ? { open1 } : {}),
                      ...(close1 && close1 !== 'Invalid Date'
                        ? { close1 }
                        : {}),
                      ...(open2 && open2 !== 'Invalid Date' ? { open2 } : {}),
                      ...(close2 && close2 !== 'Invalid Date'
                        ? { close2 }
                        : {}),
                    };

                    if (Object.keys(hours).length > 0) {
                      return {
                        ...hours,
                        //only return the dayOfWeek if hours are valid
                        ...rest,
                      };
                    }

                    return null;
                  })
                  .filter(Boolean) as OpeningHours[])
              : [];

            return (
              <Form ref={formRef}>
                <Box2
                  // @ts-ignore
                  autoComplete='off'
                  ref={formRef}
                >
                  <MANeueEinrichtungTop
                    data={data}
                    traegerComponent={
                      <TraegerWrap data={data} handleChange={handleTopChange} />
                    }
                  />
                  <Zustaendige
                    handleChange={handleZustaendigerChange}
                    token={token}
                    mode={isUUID(guid) ? 'edit' : 'create'}
                    data={data}
                  />

                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <OpeningHoursPicker
                        handleCopy={async () => {
                          await handleCopyFromMonday(setValues);
                          await validateForm();
                        }}
                      />
                    </Grid>

                    {Object.keys(checkBoxKatsInitialValues()).map((key) => {
                      if (
                        isObjectKey(checkBoxKatsInitialValues(), key) &&
                        Object.keys(checkBoxKatsInitialValues()[key]).length > 0
                      ) {
                        const katKey = key as keyof FormValues['kats'];

                        const katGroupValues = values['kats']?.[key] || {};

                        const checkedCount =
                          Object.values(katGroupValues).filter(Boolean).length;

                        return (
                          <Fragment key={key}>
                            <Grid item xs={12} sm={6} lg={4}>
                              <Box1>
                                <Box
                                  component='span'
                                  color={main}
                                  fontSize='1.5rem'
                                >
                                  <span>{KAT_NICE_NAMES[key]}</span>

                                  {key === 'kat_Was' && (
                                    <Tooltip
                                      title={
                                        <Typography>
                                          Erläuterungen zu den Kategorien
                                          findest du im MUT-ATLAS
                                        </Typography>
                                      }
                                      open={openTooltip}
                                      onClose={() => setOpenTooltip(false)}
                                      onOpen={() => setOpenTooltip(true)}
                                    >
                                      <IconButton
                                        onClick={() => setOpenTooltip(true)}
                                        size='small'
                                      >
                                        <InfoOutlinedIcon fontSize='small' />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Box>
                                {Object.keys(
                                  checkBoxKatsInitialValues()[key]
                                ).map((item) => {
                                  const checked = values['kats']?.[katKey]
                                    ? // @ts-ignore
                                      values['kats']?.[katKey][item]
                                    : false;

                                  const maxLimit =
                                    checkBoxFieldCounts[key.toLowerCase()];

                                  if (checkedCount >= maxLimit) {
                                    allowedCheckboxes[
                                      katKey
                                    ] = `Maximale ${maxLimit} Anzahl erreicht.`;
                                  }

                                  return (
                                    <div key={item}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={checked}
                                            onChange={(e) => {
                                              handleChange(e);
                                              setFieldValue(
                                                `kats.${key}.${item}`,
                                                e.target.checked
                                              );
                                            }}
                                            disabled={
                                              !checked &&
                                              !!allowedCheckboxes[katKey]
                                            }
                                            name={`kats.${key}.${item}`}
                                          />
                                        }
                                        label={item}
                                      />
                                    </div>
                                  );
                                })}
                                <Typography
                                  variant='subtitle2'
                                  color='primary.info'
                                >
                                  {allowedCheckboxes[katKey]}
                                </Typography>
                              </Box1>
                            </Grid>

                            {/* Show was details after kat_was */}
                            {key === 'kat_Was' && (
                              <Grid item xs={12} sm={6} lg={4}>
                                <Box1>
                                  <MultipleSelect
                                    data={katWasDetailsSelect()}
                                  />
                                </Box1>
                              </Grid>
                            )}
                          </Fragment>
                        );
                      }
                    })}
                  </Grid>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: { xs: 'column', md: 'row' },
                      gap: '1em',
                      width: '100%',
                      marginTop: '2em',
                    }}
                  >
                    <BackBtn /> {/* zurück */}
                    <SpeichernBtn
                      token={token}
                      data={{
                        ...data,
                        ...newValues,
                        openingHours: values.einsGeoeffnetDurchgehend
                          ? []
                          : openingHours ?? [],
                        neueKlientenApi: values.neueKlientenApi ?? '',
                        mfAngebotMultifieldValues: categoriesSeletected,
                      }}
                      operation={guid ? 'edit' : 'create'}
                      handleValidateFields={() => handleValidateFields(formik)}
                      isValid={formik.isValid && !isUnauthorized}
                    />
                  </Box>
                </Box2>
              </Form>
            );
          }}
        </Formik>

        <UnauthorizedDialog
          open={isUnauthorized}
          handleClose={() => setIsUnauthorized(false)}
        />
        <Snackbar
          open={snackbarctx.open}
          autoHideDuration={2000}
          onClose={snackbarctx.handleClose}
          message={snackbarctx.message}
          action={
            <Box>
              <Button
                color='secondary'
                size='small'
                onClick={(e) => snackbarctx.handleClose(e, 'clickaway')}
              >
                Schliessen
              </Button>
              {/* @ts-ignore */}
              <IconButton
                size='small'
                aria-label='close'
                color='inherit'
                onClick={snackbarctx.handleClose}
              >
                <CloseIcon fontSize='small' />
              </IconButton>
            </Box>
          }
        />
      </>
    </TraegerProvider>
  );
}
