import { Traeger } from '@components/dashboard-ma/components/Traeger/components/Traeger';
import CreateTraegerModal, {
  TInitTraegerData,
} from '@components/dashboard-ma/components/Traeger/components/CreateTraegerModal';
import { Token } from '../../../../context/context';
import React from 'react';
import {
  Button,
  SelectChangeEvent,
  Box,
  Typography,
  Checkbox,
} from '@mui/material';
import { Angebot } from '../../../../interfaces';

export default function TraegerWrap({
  handleChange,
  data,
}: {
  handleChange: (
    ev: React.ChangeEvent | SelectChangeEvent<string>,
    name: string
  ) => void;
  data: Angebot;
}) {
  const [open, setOpen] = React.useState(false);
  const [traeger, setTraeger] = React.useState(data.refLfNrTraeger);

  return (
    <>
      <Box
        sx={{
          gridRowStart: 'trager',
          gridColumnStart: 1,
          gridColumnEnd: 4,
        }}
      >
        <Token.Consumer>
          {(token) => (
            <>
              <Traeger
                handleChange={handleChange}
                token={token}
                refLfNrTraeger={traeger}
              />

              <CreateTraegerModal
                data={data}
                open={open}
                setOpen={setOpen}
                token={token}
                setNewTraeger={setTraeger}
              />
            </>
          )}
        </Token.Consumer>
      </Box>

      <Button
        sx={{
          gridRowStart: 'trager',
          gridColumnStart: 4,
          gridColumnEnd: 4,
          maxHeight: '55px',
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        Träger hinzufügen
      </Button>
    </>
  );
}
