import styled from 'styled-components';


export const DashboardBox = styled.section`
	background-color: #fff;
	color: black;
	border-radius: 5px;
	text-align: ${props => props.textAlign || "center"};
	font-weight: bold;
	padding: 4em 2em 0;
	padding-bottom: 2.7em;
	h1 {
		color: ${props => props.h1Color || "black"};
		font-size: 1.4em;

	}
	p {
		font-size: 1.2em;
		color: #808080;
		margin-top: 2em;
		line-height: 1.4em;
	}

`

export default DashboardBox

