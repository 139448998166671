import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

export default function BackBtn() {
  let h = useHistory();
  return (
    <Button
      sx={{ float: 'left' }}
      variant='contained'
      color={'error'}
      position='bottom'
      onClick={() => h.goBack()}
    >
      <ChevronLeftIcon />
      Zurück
    </Button>
  );
}
