import { createTheme } from '@mui/material/styles';

/*
#00B2B0 grün
#D8DC40 hell grün
#FF6920 orange
#808080 grau
*/

const drawerWidth = 240;

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontWeight: '500',
    color: '#000',
    h1: {
      fontSize: '1.6rem',
      margin: '2.7rem 0 2rem',
      color: 'var(--dark-green)',
      fontWeight: '500',
      fontFamily: 'Roboto, sans-serif',
    },
    h2: {
      color: '#808080',
      fontWeight: '500',
      fontSize: '1.3rem',
      fontFamily: 'Roboto, sans-serif',
      marginBottom: '1.2rem',
    },
    dashboardHead: {
      fontSize: '1.6rem',
      fontWeight: '600',
      fontFamily: 'Roboto',
    },
    info: {
      color: 'var(--orange)',
    },
    p: {
      textAlign: 'left',
      fontWeight: 500,
    },
    articleDate: {
      float: 'left',
      padding: '.7em 0',
    },
    articleName: {
      textAlign: 'left',
      fontSize: '1rem',
      fontWeight: 600,
    },
    articleDesc: {
      fontSize: '1rem',
      fontWeight: 'normal',
    },
  },
  palette: {
    primary: {
      main: '#00AAAD',
      info: '#2D72D9',
    },
    secondary: {
      main: '#00AAAD',
    },
    warning: {
      main: '#9d2424',
    },
  },
  components: {
    // Name of the component
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingTop: 0,
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          padding: '24px 0',
          border: 'red',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto, sans-serif',
          width: `${drawerWidth}px`,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: `${drawerWidth}px`,
            boxSizing: 'border-box',
          },
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        root: {
          '& div': { padding: '7px 31px 7px 10px' },
        },
      },
    },

    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          '& li': {
            display: 'inline',
            listStyletType: 'none',
          },
          '& a': {
            textDecoration: 'none',
            color: '#808080',
          },
          '& button': { fontSize: '1rem' },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          // textTransform: 'none',
        },
      },
      variants: [
        {
          props: { position: 'bottom' },
          style: {
            marginTop: '2em',
          },
        },
        //  {
        //   props: { text: 'uppercase' },
        //   style: {
        //     textTransform: 'uppercase'
        //   },
        // },
      ],
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '1.6rem',
        },
      },
    },

    // MuiTableRoot: {
    //   styleOverrides:{
    //       root:{
    //         minWidth: 340,
    //       }
    //   }
    // },

    MuiTableHead: {
      styleOverrides: {
        root: {
          background: '#d3d8db',
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          color: '#808080',
          fontSize: '1rem',
          lineHeight: '2.5',
          fontWeight: '500',
          padding: 5,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontWeight: 500,
        },
      },
    },
  },
});

export { theme };
