import { AuthContextProps } from 'react-oidc-context';

function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

const getRoles = (auth: AuthContextProps) => {
  const parsed_token = parseJwt(auth.user?.access_token ?? '');
  const client_id = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
  if (client_id) {
    return parsed_token.resource_access[client_id].roles ?? [];
  }
  return [];
};

export { getRoles };
