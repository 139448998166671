import { Box, Typography, Button } from '@mui/material';
import MutAtlasLinkButton from '@components/dashboard-ma/components/MutAtlasLinkButton';
import { Box2 } from '@components/dashboard-ma/MAEinrichtung/MAEinrichtung-Styles';
import React from 'react';
import { Angebot } from '../../../interfaces';
import { getDate } from '../../../utils/getDate';

export function Header({ data, guid }: { data: Angebot; guid: string }) {
  const date = getDate(data.syncTime);
  const dateValidiert = getDate(data.zuletztValidiertAm);

  return (
    <Box2>
      {guid && <Typography variant='h1'>Angebot bearbeiten</Typography>}
      {!guid && <Typography variant='h1'>Neues Angebot</Typography>}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        {data.autoAndName && data.autoAndName.length > 0 && (
          <Typography variant='body1' component='div'>
            zuletzt geändert von: &nbsp;<em>{data.modifiedBy}</em>
            &nbsp;am&nbsp;
            {date}
          </Typography>
        )}
        {data.zuletztValidiertVon && data.zuletztValidiertVon.length > 0 && (
          <Typography variant='body1' component='div'>
            Validierungsstatus geändert von: &nbsp;<em>{data.zuletztValidiertVon}</em>
            &nbsp;am&nbsp;
            {dateValidiert}
          </Typography>
        )}
        {
          <div>
            <Button color='secondary' variant='contained' target="_blank" rel="noreferrer" href="https://orga.mut-foerdern.de/s/sGZQcYoiSwDRRoR">
              Anleitung als pdf herunterladen
            </Button>
          </div>
        }
        {guid && (
          <MutAtlasLinkButton
            latitude={data.latitude}
            longitude={data.longitude}
          />
        )}
      </Box>
    </Box2>
  );
}
