import { Button } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import React from 'react';
import { getMutAtlasLink } from '../../../utils/getMutAtlasLink';

export default function MutAtlasLinkButton({
  latitude,
  longitude,
}: {
  latitude: number | undefined;
  longitude: number | undefined;
}) {
  const link = getMutAtlasLink(latitude, longitude);

  if (latitude && latitude !== 0)
    return (
      <div>
        <a
          href={link}
          target='_blank'
          rel='nofollow, noreferrer'
          style={{
            textDecoration: 'none',
            marginBottom: '2em',
            display: 'block',
          }}
        >
          <Button color='secondary' variant='contained'>
            <MapIcon sx={{ mr: 2 }} />
            Auf Karte ansehen
          </Button>
        </a>
      </div>
    );

  return (
    <div>
      <small>
        GPS-Informationen liegen noch nicht vor. <br />
        Dieses Angebot kann noch nicht auf der Karte angezeigt werden.
      </small>
    </div>
  );
}
