/*

"angebotePos": [
    {
      "fileMakerRecordId": 0,
      "lfNrAdress": "string",
      "lfNrAngebote": "string",
      "funktion": "string",
      "angebotAdresse": {
        "fileMakerRecordId": 0,
        "lfNrAdresse": "string",
        "vorname": "string",
        "nachname": "string",
        "strasse": "string",
        "hausnummer": "string",
        "strasseZusatz": "string",
        "plz": "string",
        "ort": "string",
        "eMail": "string",
        "telefon1": "string",
        "handy": "string",
        "geburtsDatum": "2023-03-14T15:30:02.326Z",
        "autoAndZeitstempel": "2023-03-14T15:30:02.326Z"
      }
    }
  ],

 */
export const fieldsConfig = [
  {
    label: 'Funktion',
    name: 'funktion',
    type: 'text',
  },
  {
    label: 'Vorname',
    name: 'vorname',
    type: 'text',
  },
  {
    label: 'Nachname',
    name: 'nachname',
    type: 'text',
  },
  {
    label: 'E-Mail',
    type: 'email',
    name: 'email',
  },
  {
    label: 'Telefon',
    type: 'text',
    name: 'telefon',
    tooltip:
      'Wenn die Telefonnr. identisch mit der Telefonnr. im Angebot ist, dann hier bitte nicht eintragen!',
  },
  {
    label: 'Handy',
    type: 'text',
    name: 'handy',
  },
];
