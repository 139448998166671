import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Fragment, memo } from 'react';
import { OpeningHours } from '../../../interfaces';
import { FormValues } from '../MAEinrichtung';
import { Box1 } from '../MAEinrichtung/MAEinrichtung-Styles';

export interface OpeningHoursForm {
  dayOfWeek: number;
  openings: {
    open: string;
    close: string;
  }[];
}
interface Props {
  handleCopy: () => void;
}

const weekdays: Record<number, string> = {
  1: 'Montag',
  2: 'Dienstag',
  3: 'Mittwoch',
  4: 'Donnerstag',
  5: 'Freitag',
  6: 'Samstag',
  7: 'Sonntag',
};

const OpeningHoursPicker = ({ handleCopy }: Props) => {
  const { values, setFieldValue, handleChange, errors } =
    useFormikContext<FormValues>();

  const transformOpeningHoursError = (
    errors.openingHours as unknown as Array<Partial<OpeningHours>>
  )?.reduce((acc, curr) => {
    const { dayOfWeek, ...rest } = curr;
    if (!dayOfWeek) return acc;
    if (!acc[dayOfWeek]) {
      acc[dayOfWeek] = {};
    }
    acc[dayOfWeek] = { ...acc[dayOfWeek], ...rest };
    return acc;
  }, {} as Record<number, Partial<OpeningHours>>);

  return (
    <Box1>
      <Typography variant='h1' sx={{ marginBottom: '1rem' }}>
        Öffnungszeiten
      </Typography>

      <FormGroup sx={{ gap: 2 }}>
        <Box
          display={'flex'}
          alignItems={'center'}
          sx={{
            gridColumnStart: 1,
            gridColumnEnd: 5,
            cursor: 'pointer',
            marginBottom: '1em',
          }}
          component={'label'}
        >
          <Checkbox
            name='einsGeoeffnetDurchgehend'
            checked={values['einsGeoeffnetDurchgehend'] ?? false}
            onChange={(e) => {
              handleChange(e);
            }}
            sx={{ padding: '0', marginRight: '.5em' }}
          />
          <Typography component='span'>durchgehend geöffnet</Typography>
        </Box>

        <Box
          display={'flex'}
          alignItems={'center'}
          sx={{
            gridColumnStart: 6,
            gridColumnEnd: 10,
            cursor: 'pointer',
            marginBottom: '1em',
          }}
          component={'label'}
        >
          <Checkbox
            name='einsGeoeffnetNachVereinbarung'
            checked={values['einsGeoeffnetNachVereinbarung'] ?? false}
            onChange={(e) => {
              handleChange(e);
            }}
            sx={{ padding: '0', marginRight: '.5em' }}
          />
          <Typography component='span'>
            (zusätzliche) Termine nach Vereinbarung
          </Typography>
        </Box>

        <Box display='flex'>
          <Box minWidth={100}></Box>
          <Grid container spacing={2}>
            <Grid
              item
              xs={3}
              sm={6}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Öffnungszeit 1
            </Grid>
            <Grid
              item
              xs={3}
              sm={6}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Öffnungszeit 2 (falls es z.B. eine Mittagspause gibt)
            </Grid>
          </Grid>
        </Box>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FieldArray name='openingHours'>
            {() => (
              <>
                {Array.from({ length: 7 }).map((_, dayIndex) => {
                  const open1 = values.openingHours?.[dayIndex]?.open1;
                  const close1 = values.openingHours?.[dayIndex]?.close1;
                  const open2 = values.openingHours?.[dayIndex]?.open2;
                  const close2 = values.openingHours?.[dayIndex]?.close2;

                  return (
                    <Fragment key={dayIndex}>
                      <Box display='flex'>
                        <Box minWidth={100}>{weekdays[dayIndex + 1]}</Box>
                        <Grid container spacing={2}>
                          {(
                            [
                              { name: 'open1', value: open1 },
                              { name: 'close1', value: close1 },
                              { name: 'open2', value: open2 },
                              { name: 'close2', value: close2 },
                            ] as const
                          ).map(({ name, value }) => {
                            return (
                              <Grid
                                item
                                xs={3}
                                sm={3}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  flexDirection: 'column',
                                }}
                                key={name}
                              >
                                <Field
                                  as={TimePicker}
                                  ampm={false}
                                  name={`openingHours.${dayIndex}.${name}`}
                                  value={
                                    dayjs(value, 'HH:mm').isValid()
                                      ? dayjs(value, 'HH:mm')
                                      : null
                                  }
                                  onChange={(e: any) => {
                                    const value = e?.format('HH:mm');

                                    const isValidDate = dayjs(
                                      value,
                                      'HH:mm'
                                    ).isValid();

                                    setFieldValue(
                                      `openingHours.${dayIndex}.${name}`,
                                      !isValidDate ? null : value,
                                      true
                                    );

                                    !isValidDate &&
                                      setFieldValue(
                                        `openingHours.${dayIndex}.dayOfWeek`,
                                        dayIndex + 1
                                      );
                                  }}
                                  label={name.includes('open') ? 'Von' : 'Bis'}
                                  variant='outlined'
                                  disabled={values.einsGeoeffnetDurchgehend}
                                />
                                {!!transformOpeningHoursError?.[dayIndex + 1]?.[
                                  name
                                ] && (
                                  <Box
                                    component='span'
                                    display='flex'
                                    alignSelf='start'
                                    fontSize='0.75rem'
                                    color='#d32f2f'
                                    id={`openingHours.${dayIndex}.${name}`}
                                  >
                                    {
                                      transformOpeningHoursError[dayIndex + 1][
                                        name
                                      ]
                                    }
                                  </Box>
                                )}
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Box>

                      {dayIndex === 0 && (
                        <Box>
                          <Button
                            type='button'
                            variant='outlined'
                            onClick={() => handleCopy()}
                            disabled={values.einsGeoeffnetDurchgehend}
                          >
                            <Typography component='span' mr={1}>
                              Offnungszeiten von Montag Kopieren
                            </Typography>

                            <ContentCopyIcon fontSize='small' />
                          </Button>
                        </Box>
                      )}
                    </Fragment>
                  );
                })}
              </>
            )}
          </FieldArray>
        </LocalizationProvider>
      </FormGroup>
    </Box1>
  );
};

export default memo(OpeningHoursPicker);
