export function isObjectKey<T extends object>(
  x: T,
  k: PropertyKey
): k is keyof T {
  return k in x;
}

export function isUUID(str: string): boolean {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    str
  );
}
