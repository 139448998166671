import React from 'react';
import './App.css';

import { AuthProvider, useAuth } from 'react-oidc-context';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import LoadingScreen from '@components/LoadingScreen';
import Dashboard from '@components/Dashboard';
import Login from '@components/Login';
import { Token } from './context/context';

export default function App() {
  const oicd_config = {
    realm: process.env.REACT_APP_KEYCLOAK_REALM ?? '',
    authority: process.env.REACT_APP_KEYCLOAK_URL ?? '',
    client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? '',
    redirect_uri: process.env.REACT_APP_REDIRECT_URI ?? '',
  };

  return (
    <AuthProvider {...oicd_config}>
      <AuthConsumer />
    </AuthProvider>
  );
}

const AuthConsumer = () => {
  const auth = useAuth();

  const tkn = auth.user?.access_token;
  const token = tkn ?? '';

  if (auth.isLoading) return <LoadingScreen />;
  if (!auth.isAuthenticated) return <Login />;

  return (
    <Token.Provider value={token}>
      <Router>
        <Route path='/login'>
          <Login />
        </Route>
        <Route path='/' component={Dashboard} />
        {/*<Redirect exact from='/' to='/dashboard' />*/}
      </Router>
    </Token.Provider>
  );
};
