import styled from 'styled-components';
import { Box } from '@mui/material';
import { DashboardBox } from '../../DashboardBox';

export const Box1 = styled(DashboardBox)`
  text-align: left;
  padding: 0.9em 2em 2.3em 2em;

  h1 {
    color: var(--dark-green);
    margin: 0.3em 0 1.5em;
  }
`;

export const Box2 = styled(Box)`
  @media (min-width: 768px) {
    padding: 2em;
  }
`;
