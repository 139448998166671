import React, { useState } from 'react';

const Context = React.createContext<any>({});
Context.displayName = 'SnackBar';

export const useSnackbarContext = () => React.useContext(Context);

export const SnackbarProvider = ({ children }: { children: JSX.Element }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const handleClose = (event: any, reason: string) => {
    setOpen(false);
  };

  return (
    <Context.Provider
      value={{
        open: open,
        setOpen: setOpen,
        message: message,
        setMessage: setMessage,
        handleClose: handleClose,
      }}
    >
      {children}
    </Context.Provider>
  );
};
