export const topFormFields = [
  {
    name: 'name',
    label: 'Name des Angebots (mit Ortsname)',
    tooltip:
      'Bitte unbedingt Orts(teil)name am Ende ergänzen, also z.B. "Sozialpsychiatrischer Dienst Bremen Nord"',
    gridRowStart: 'eins',
    required: true,
    gridColumnStart: 1,
    gridColumnEnd: 5,
  },

  {
    name: 'strasse',
    label: 'Strasse',
    required: true,
    gridRowStart: 'zwei',
    gridColumnStart: 1,
    gridColumnEnd: 4,
  },

  {
    name: 'hausnummer',
    label: 'Hausnummer',
    required: true,
    gridRowStart: 'zwei',
    gridColumnStart: 4,
    gridColumnEnd: 5,
  },
  {
    name: 'zusatz',
    label: 'Zusatz',
    gridRowStart: 'drei',
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },
  {
    name: 'telefon',
    label: 'Telefon',
    gridRowStart: 'drei',
    gridColumnStart: 3,
    required: true,
    gridColumnEnd: 5,
    type: 'text',
  },
  {
    name: 'plz',
    label: 'PLZ',
    gridRowStart: 'vier',
    gridColumnStart: 1,
    required: true,
    gridColumnEnd: 2,
    type: 'text',
  },

  {
    name: 'ort',
    label: 'Ort',
    gridRowStart: 'vier',
    gridColumnStart: 2,
    gridColumnEnd: 5,
  },

  {
    name: 'postfachPLZ',
    label: 'Postfach PLZ',
    gridRowStart: 'fuenf',
    gridColumnStart: 1,
    gridColumnEnd: 2,
    type: 'text',
  },

  {
    name: 'postfachNr',
    label: 'Postfach Nr.',
    type: 'text',
    gridRowStart: 'fuenf',
    gridColumnStart: 2,
    gridColumnEnd: 5,
  },

  {
    name: 'email',
    label: 'E-Mail',
    type: 'mail',
    gridRowStart: 'sechs',
    required: true,
    gridColumnStart: 1,
    gridColumnEnd: 5,
  },

  {
    name: 'homePage',
    label: 'Website',
    type: 'url',
    gridRowStart: 'sieben',
    required: true,
    tooltip:
      'Direkter Link zum Angebot. Falls nicht vorhanden: Link zur Homepage.',
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },
  {
    name: 'beschreibung',
    label: 'Beschreibung (öffentlich)',
    gridRowStart: 'acht',
    tooltip:
      'Beschreibung des Angebotes (wird im MUT-ATLAS veröffentlicht)',
    gridColumnStart: 1,
    gridColumnEnd: 5,
  },
  {
    name: 'bemerkungen',
    label: 'Interne Bemerkungen',
    gridRowStart: 'neun',
    tooltip:
      'Bitte hier interne Anmerkungen oder auch zusätzlich interessante, kurze Texte zum Angebot einfügen. Der Inhalt dieses Feldes ist im MUT-ATLAS erstmal nicht sichtbar.',
    gridColumnStart: 1,
    gridColumnEnd: 5,
  },
];
