import { postData } from '@components/_api';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useSnackbarContext } from '../context/SnackBarContext';
import { Angebot } from '../interfaces';

type InputType = {
  data: Angebot;
  token: string;
  operation: 'edit' | 'create';
  text?: string;
  isValid?: boolean;
  handleValidateFields: () => Promise<boolean>;
};

export default function SpeichernBtn({
  data,
  token,
  operation,
  text,
  isValid,
  handleValidateFields,
}: InputType) {
  const history = useHistory();
  const { setMessage, setOpen } = useSnackbarContext();

  const createNew = () => {
    // remove angebotePos, otherwise it fails
    const { angebotePos, ...rest } = data;

    postData({
      endpoint: 'AngeboteAuth/createangebot',
      json: rest,
      token,
      apiversion: 'v2',
    }).then((resp) => {
      switch (resp.status) {
        case 200:
          if (data.angebotePos && data.angebotePos.length > 0)
            saveAngebotePerson(resp.data.autoLfNr);

          history.push('/mut-atlas');
          setOpen(true);
          setMessage(
            'Vielen Dank! Das Anlegen hat geklappt. Ab morgen wird das Angebot auch im MUT-ATLAS angezeigt.'
          );
          break;
        default:
          setOpen(true);
          setMessage('Ein Fehler ist aufgetreten.');
          break;
      }
    });
  };

  const saveAngebotePerson = (autoLfNr: string | undefined) => {
    if (data.angebotePos && data.angebotePos?.length > 0 && autoLfNr) {
      axios.all(
        data.angebotePos.map(async (person) => {
          const { telefon, email, vorname, nachname, handy, funktion } = person;
          return await postData({
            endpoint: 'AngeboteAuth/createangebotap',
            json: {
              telefon,
              email,
              vorname,
              nachname,
              handy,
              funktion,
              lfNrAngebote: autoLfNr,
            },
            token,
            apiversion: 'v2',
          }).then((resp) => {
            switch (resp.status) {
              case 200:
                return resp.data;
              default:
                setOpen(true);
                setMessage(
                  'Ein Fehler ist aufgetreten beim speichern der Person.'
                );
                break;
            }
          });
        })
      );
    }
  };

  /* Speichern */
  const handleClick = async () => {
    const valid = await handleValidateFields();
    // if not valid, do not save
    // valid is checking for validation when user has not touched the fields but wants to save
    // isValid is checking for validation when user has touched the fields
    if (!isValid || !valid) return;
    switch (operation) {
      case 'edit':
        postData({
          endpoint: 'AngeboteAuth/edituserangebot',
          json: data,
          token,
          apiversion: 'v2',
        }).then((resp) => {
          switch (resp.status) {
            case 200:
              history.push('/mut-atlas');

              setOpen(true);
              setMessage(
                'Gespeichert. Die Änderungen werden ab morgen auch im MUT-ATLAS angezeigt.'
              );
              break;
            default:
              setOpen(true);
              setMessage('Ein Fehler ist aufgetreten.');
              break;
          }
        });
        history.push('/mut-atlas');
        break;
      case 'create':
        createNew();
        break;
    }
  };

  return (
    <>
      <Button onClick={() => handleClick()} variant='contained'>
        <SaveAltIcon sx={{ mr: 2 }} />
        {text ?? 'Speichern'}
      </Button>
    </>
  );
}
