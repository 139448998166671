import React, { Suspense, useState } from 'react';
import { theme } from '../theme';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DashboardBox } from './DashboardBox';
import DashboardAppbar from './DashboardAppbar';
import DrawerLinks from './dashboard-main/DrawerLinks';
import { SnackbarProvider } from '../context/SnackBarContext';
import Toolbar from '@mui/material/Toolbar';
import { Route, Switch } from 'react-router-dom';
import LoadingIndicator from '@components/LoadingIndicator';
import { Token } from '../context/context';
import MAEinrichtung from '@components/dashboard-ma/MAEinrichtung';
import MAEinrichtungen from '@components/dashboard-ma/MAEinrichtungen';
import _load_multifieldcounts from '../utils/_load_multifieldcounts';

/* Code Splitting */
const DashboardMT = React.lazy(
  () => import('@components/dashboard-mt/DashboardMutTour')
);
const DashboardMF = React.lazy(
  () => import('@components/dashboard-mf/DashboardMutFoerdern')
);
const DashboardMA = React.lazy(
  () => import('@components/dashboard-ma/DashboardMutAtlas')
);
const Datenschutz = React.lazy(
  () => import('@components/dashboard-mt/Datenschutz')
);
const Stammdaten = React.lazy(() => import('@components/Stammdaten'));
const Hilfe = React.lazy(() => import('@components/hilfe/Hilfe'));

const drawerWidth = 240;

_load_multifieldcounts();

export default function Dashboard() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <Typography variant='body1' component='div'>
        <Box sx={{ display: 'flex' }}>
          {/* Menü Leiste oben */}
          <DashboardAppbar
            drawerWidth={drawerWidth}
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />

          {/* Drawer Links*/}
          <DrawerLinks
            drawerWidth={drawerWidth}
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />

          <SnackbarProvider>
            <Box
              component='main'
              sx={{
                flexGrow: 1,
                bgcolor: '#f4f7fc',
                p: { xs: '1em', md: '2em' },
                maxWidth: '1500px',
                width: { md: `calc(100% - ${drawerWidth}px)` },
                overflowX: 'auto',
              }}
            >
              <Toolbar />
              <Token.Consumer>
                {(token) => (
                  <Suspense fallback={<LoadingIndicator />}>
                    <Switch>
                      <Route path={`/mut-atlas/einrichtung/:guid`}>
                        <MAEinrichtung token={token} />
                      </Route>
                      <Route path={`/mut-atlas/einrichtung/`}>
                        <MAEinrichtung token={token} />
                      </Route>
                      <Route path={`/mut-atlas/einrichtungen/`}>
                        <MAEinrichtungen token={token} />
                      </Route>
                      <Route path={`/mut-atlas`}>
                        <DashboardMA />
                      </Route>
                      <Route path='/stammdaten'>
                        <Stammdaten token={token} />
                      </Route>
                      <Route path='/datenschutz'>
                        <Datenschutz />
                      </Route>
                      <Route path='/hilfe'>
                        <Hilfe />
                      </Route>
                      <Route path='/mut-tour'>
                        <DashboardMT />
                      </Route>
                      <Route path='/mut-foerdern'>
                        <DashboardMF />
                      </Route>
                      <Route exact path='/'>
                        <DashboardBox>
                          Bitte wähle oben eine Abteilung
                        </DashboardBox>
                      </Route>
                    </Switch>
                  </Suspense>
                )}
              </Token.Consumer>
            </Box>
          </SnackbarProvider>
        </Box>
      </Typography>
    </ThemeProvider>
  );
}
