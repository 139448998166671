import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';


export default function LoadingIndicator(){
  return (
    <Box sx={{display: 'flex'}}>
      {
        (Array.from(new Array(5)).map((item, index) => (
          <Box key={index}>
            <Skeleton variant="rectangular" width={210} height={150} sx={{ margin: '1em'}}/> 
            <Box sx={{ m: '1em' }}>
                <Skeleton />
                <Skeleton width="60%" />
            </Box>
          </Box>
        )))
      }
    </Box>
  )
}