import { postData } from '@components/_api';
import { InputTooltip } from '@components/common/InputTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';
import * as React from 'react';
import { useSnackbarContext } from '../../../../../context/SnackBarContext';
import { Angebot } from '../../../../../interfaces';
import {
  error,
  style,
} from '../../Traeger/components/CreateTraegerModal_Styles';
import { PersonDataType } from '../Zustaendige';
import { fieldsConfig } from './fields';

export default function CreateZustaendigenModal({
  open,
  handleClose,
  currentPerson,
  data,
  token,
  handlePersons,
  mode,
}: {
  open: boolean;
  handleClose: () => void;
  handlePersons: (person: PersonDataType[]) => void;
  data?: Angebot;
  currentPerson?: PersonDataType;
  token: string;
  mode: 'edit' | 'create';
}) {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState<{
    [key: string]: boolean;
  }>({ name: false });

  const { setMessage, setOpen } = useSnackbarContext();

  const handleFormSubmit = async (values: TInitData) => {
    if (mode === 'edit' && data) {
      const existingPerson = data.angebotePos ?? [];

      await postData({
        endpoint: 'AngeboteAuth/createangebotap',
        json: { ...values, lfNrAngebote: data.autoLfNr },
        token,
        apiversion: 'v2',
      }).then((resp) => {
        if (resp.status === 200) {
          setOpen(true);
          setMessage('Person erfolgreich gespeichert');
          handlePersons([...existingPerson, resp.data]);
          handleClose();
        }
      });
    } else {
      const existingPerson = data && data.angebotePos ? data.angebotePos : [];

      handlePersons([
        ...existingPerson,
        { ...values } as unknown as PersonDataType,
      ]);
      handleClose();
    }
  };

  const { nachname, telefon, email, handy, vorname, funktion } =
    currentPerson || ({} as PersonDataType);

  const initFormData = {
    funktion: funktion ?? '',
    vorname: vorname ?? '',
    nachname: nachname ?? '',
    telefon1: telefon ?? '',
    email: email ?? '',
    handy: handy ?? '',
  };

  type TInitData = typeof initFormData;

  const validate = (values: TInitData) => {
    const errors: Partial<TInitData> = {};

    if (!values.funktion) errors.funktion = 'Benötigt';
    if (!values.vorname) errors.vorname = 'Benötigt';
    if (!values.nachname) errors.nachname = 'Benötigt';

    if (
      values.email.length > 0 &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    )
      errors.email = 'Ungültige Email-Addresse';

    if (
      values.telefon1.length > 0 &&
      !/^\+*(\d.*){3,}[\s/-]?\d.*/.test(values.telefon1)
    )
      errors.telefon1 = 'Ungültige Telefon Nummer';

    if (values.telefon1.length > 0 && data?.telefon === values.telefon1)
      errors.telefon1 =
        'Telefon Nummer identisch mit der Telefonnr. im Angebot';

    return errors;
  };

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div>
            <div>
              <Typography
                variant='h2'
                sx={{
                  marginBottom: '2rem',
                }}
              >
                Zuständige Person hinzufügen
              </Typography>
            </div>
            <div>
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initFormData}
                validate={validate}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid
                      container
                      spacing={2}
                      columns={{ xs: 4, sm: 8, md: 16 }}
                    >
                      {fieldsConfig &&
                        fieldsConfig.map(({ name, label, type, tooltip }) => {
                          const nameKey = name as keyof TInitData;
                          return (
                            <Grid item xs={8} pt={0} key={label}>
                              <InputTooltip
                                name={nameKey}
                                tooltip={tooltip}
                                open={isTooltipOpen[nameKey] ?? false}
                                handleOpen={() =>
                                  setIsTooltipOpen({
                                    [nameKey]: true,
                                  })
                                }
                                handleClose={() =>
                                  setIsTooltipOpen({
                                    [nameKey]: false,
                                  })
                                }
                              >
                                <TextField
                                  sx={{
                                    width: '100%',
                                  }}
                                  key={label}
                                  InputLabelProps={{ shrink: !!label }}
                                  type={type}
                                  id={name}
                                  label={label}
                                  variant='outlined'
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                  value={values[nameKey]}
                                  placeholder={label}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position='end'>
                                        {tooltip && (
                                          <InfoOutlinedIcon
                                            fontSize='small'
                                            onClick={() => {
                                              setIsTooltipOpen({
                                                [nameKey]: true,
                                              });
                                            }}
                                          />
                                        )}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </InputTooltip>
                              {errors &&
                                errors[nameKey] &&
                                touched[nameKey] && (
                                  <Box sx={error}>{errors[nameKey]}</Box>
                                )}
                            </Grid>
                          );
                        })}
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        mt: '2em',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Button
                        color={'error'}
                        onClick={handleClose}
                        variant='contained'
                      >
                        Abbrechen
                      </Button>
                      <Button
                        type={'submit'}
                        variant='contained'
                        disabled={Object.keys(errors).length > 0}
                      >
                        {mode === 'edit' ? 'Speichern' : 'Hinzufügen'}
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
