import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useFormikContext } from 'formik';
import { EditableAngebotInputObject } from '../../interfaces';
import { FormValues } from '@components/dashboard-ma/MAEinrichtung';

interface SwitchProps {
  name: string;
  title: string;
  data: EditableAngebotInputObject;
}

export default function CustomSwitch({ data, name, title }: SwitchProps) {
  const { setFieldValue, values } = useFormikContext<FormValues>();

  const neueKlientenApi = values[name as keyof FormValues];

  const alignment =
    neueKlientenApi === 'unbekannt' || neueKlientenApi === ''
      ? 'unbekannt'
      : neueKlientenApi;

  return (
    <ToggleButtonGroup
      title={title}
      value={alignment}
      exclusive
      onChange={(e, value) => {
        // unbekannt can only be set from FM, so only allow unbekannt to be set if it has already been set
        value !== 'unbekannt' ||
        (data.neueKlientenApi === 'unbekannt' && value === 'unbekannt')
          ? setFieldValue(name, value, true)
          : e.preventDefault();
      }}
      aria-label='text alignment'
      size='small'
    >
      <ToggleButton
        value='ja'
        aria-label='left aligned'
        sx={{
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
          padding: 1,
        }}
        color='primary'
      >
        Ja
      </ToggleButton>
      <ToggleButton
        value='unbekannt'
        aria-label='centered'
        sx={{
          padding: 1,
        }}
        disabled={data.neueKlientenApi !== 'unbekannt'}
        color='standard'
      >
        Unbekannt
      </ToggleButton>
      <ToggleButton
        value='nein'
        aria-label='right aligned'
        sx={{
          borderTopRightRadius: 20,
          borderBottomRightRadius: 20,
          padding: 1,
        }}
        color='error'
      >
        Nein
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
