import React, { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

import {
  List,
  ListItem,
  Toolbar,
  AppBar,
  Button,
  Box,
  IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { getRoles } from '../utils/getRoles';

type DashboardTypes = {
  drawerWidth: number;
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
};

export default function Dashboard({
  drawerWidth = 240,
  // mobileOpen,
  handleDrawerToggle,
}: DashboardTypes) {
  const { signoutRedirect } = useAuth();
  const auth = useAuth();
  const roles = getRoles(auth);
  const { pathname } = useLocation();

  const isScout = roles.includes('MT-Mut-Scout');

  const links = [
    {
      title: 'Mut Tour',
      to: '/mut-tour',
      shouldShowLink:
        roles.includes('MT-Teilnehmende') || roles.includes('MT-Leitende'),
    },
    {
      title: 'Mut Atlas',
      to: '/mut-atlas',
      shouldShowLink: isScout,
    },
    {
      title: 'Mut fördern e.V.',
      to: '/mut-foerdern',
      shouldShowLink: isScout,
    },
  ];

  return (
    <AppBar
      position='fixed'
      sx={{
        width: { md: `calc(100% - ${drawerWidth}px)` },
        ml: { md: `${drawerWidth}px` },
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          maxWidth: '1800px',
        }}
      >
        <IconButton
          aria-label='open drawer'
          edge='start'
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>

        <Box component='nav' aria-label='Auswahl der Abteilung'>
          {links.map((link) => {
            const { title, to, shouldShowLink } = link;
            const isActive = pathname.includes(to);
            // Role switch
            return (
              <Fragment key={title}>
                {shouldShowLink ? (
                  <Link to={to}>
                    <Button
                      color={isActive ? 'secondary' : 'inherit'}
                      variant={isActive ? 'contained' : 'text'}
                    >
                      {title}
                    </Button>
                  </Link>
                ) : null}
              </Fragment>
            );
          })}
        </Box>

        <Box component='nav'>
          <List>
            {/*    <ListItem>
              <Link to='/'>Sprachen</Link>
            </ListItem>*/}
            <ListItem
              onClick={() => signoutRedirect()}
              sx={{ color: '#808080', cursor: 'pointer' }}
            >
              Logout
            </ListItem>
          </List>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
