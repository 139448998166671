import { Tooltip, Typography } from '@mui/material';
import { FC, ReactElement } from 'react';

interface MyComponentProps {
  children: ReactElement<any, any>;
  name: string;
  tooltip?: string;
  handleClose: () => void;
  handleOpen: () => void;
  open: boolean;
}

export const InputTooltip: FC<MyComponentProps> = ({
  tooltip,
  name,
  children,
  open,
  handleClose,
  handleOpen,
}) => {
  if (tooltip) {
    return (
      <Tooltip
        title={<Typography>{tooltip}</Typography>}
        placement='bottom-start'
        open={open}
        onClose={() => handleClose()}
        onOpen={() => handleOpen()}
      >
        {children}
      </Tooltip>
    );
  } else {
    return children;
  }
};
