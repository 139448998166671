import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import DashboardBox from '@components/DashboardBox';
import { Angebot } from '@components/../interfaces';
import { Redirect } from 'react-router-dom';
import { getDate } from '../../../utils/getDate';

function EinrichtungElement({ item }: { item: Angebot }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  if (open) {
    return <Redirect to={'/mut-atlas/einrichtung/' + item.guid} />;
  }

  return (
    <DashboardBox style={{ marginTop: '2em' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', md: 'row' },
          gap: '1em',
        }}
      >
        <Box component='span' sx={{ textAlign: 'left' }}>
          <Typography id='transition-modal-title' variant='h6' component='h2'>
            {item.name}
          </Typography>
          <Typography variant='body1' component='span'>
            geändert: {getDate(item.autoAndDatum)}
          </Typography>
        </Box>
        <Button onClick={handleOpen} color='secondary' variant='contained'>
          <EditIcon sx={{ mr: 2 }} />
          Bearbeiten
        </Button>
      </Box>
    </DashboardBox>
  );
}

export default React.memo(EinrichtungElement);
