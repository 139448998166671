import { EditableAngebotInputObject } from '../../interfaces';

const now = new Date();
const empty_data: EditableAngebotInputObject = {
  autoLfNr: '',
  name: '',
  niceName: '',
  art: '',
  typ: '',
  strasse: '',
  hausnummer: '',
  zusatz: '',
  plz: '',
  ort: '',
  postfachPLZ: '',
  postfachNr: '',
  datum: now.toISOString(),
  fax: '',
  telefon: '',
  email: '',
  homePage: '',
  beschreibung: '',
  bemerkungen: '',
  einsGeoeffnetDurchgehend: false,
  einsGeoeffnetNachVereinbarung: false,
  einsKeineNeuenKlienten: false,
  einsKeinTraeger: false,
  einsDeutschlandweit: false,
  oeffnungszeiten: '',
  arbeitszeiten: '',
  landkreis: '',
  refLfNrTraeger: '',
  latitude: 0,
  longitude: 0,
  kat_verfuegbarkeit: '',
  openingHours: [],
  KatWasSum: '',
  mfAngebotMultifieldValues: [],
};

export { empty_data };
