import styled from 'styled-components';
import login_screen from '../assets/login-screen.jpg';

export const Picture = styled.div`
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-image: url(${login_screen});
  background-position: 65%;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 768px) {
    height: 25%;
    max-height: 160px;
    background-position: 64% 41%;
  }
`;
export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 5fr;
  height: 100vh;
  margin: 0 auto;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const LoginButtons = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: buttons;
  grid-row-end: last-line;
  align-self: end;
  display: flex;
  justify-content: space-between;
  button {
    text-transform: none;
    width: 100%;
    flex-basis: 45%;
  }

  a {
    flex-basis: 45%;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    button {
      flex-basis: 47%;
    }
  }
`;
export const TopMenu = styled.aside`
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: menu;
  grid-row-end: header;
  color: var(--dark-green);
  font-weight: 800;

  ul {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }
  li {
    list-style-type: none;
    padding-right: 1em;
  }
`;

export const FormWrapper = styled.div`
  display: grid;
  grid-template-rows: [menu] 35% [header] 3.5rem [subheader] 6rem [loginform] auto [pwreset] 1em [buttons] 4.6rem [last-line];
  margin: auto auto;
  font-family: 'Roboto', 'sans-serif', sans-serif;
  min-width: 400px;

  @media (max-width: 768px) {
    padding-bottom: 5em;
    margin: 0.6em auto;
    min-width: auto;

    grid-template-rows: [menu] 25% [header] 2.9rem [subheader] 4.4rem [loginform] auto [pwreset] 1em [buttons] 4rem [last-line];
  }

  h1 {
    margin: 0;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: header;
    grid-row-end: subheader;
  }
  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 1.1rem;
    margin: 0;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: subheader;
    grid-row-end: loginform;
  }

  .pwreset {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: pwreset;
    grid-row-end: buttons;
  }

  .pwreset a {
    color: #f44336;
    text-decoration: none;
  }
`;
