import { InputTooltip } from '@components/common/InputTooltip';
import ContentPasteOutlined from '@mui/icons-material/ContentPasteOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Checkbox,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { Field, useFormikContext } from 'formik';
import { Fragment, ReactElement, useState } from 'react';
import { useTraegerContext } from '../../../context/TraegerContext';

import CustomSwitch from '@components/common/CustomSwitch';
import { FormValues } from '@components/dashboard-ma/MAEinrichtung';
import { EditableAngebotInputObject } from '../../../interfaces';
import { getOrt } from '../../../utils/getOrt';
import { topFormFields } from './topFormFields';

export default function EinrichtungTop({
  traegerComponent,
  data,
}: {
  traegerComponent: ReactElement;
  data: EditableAngebotInputObject;
}) {
  const { values, errors, touched, setFieldValue, validateField } =
    useFormikContext<FormValues>();
  const [isTooltipOpen, setIsTooltipOpen] = useState<{
    [key: string]: boolean;
  }>({ name: false });

  const handleClose = (name: string) => {
    setIsTooltipOpen({ [name as keyof FormValues]: false });
  };

  const handleOpen = (name: string) => {
    setIsTooltipOpen({ [name as keyof FormValues]: true });
  };

  const { data: traegerData } = useTraegerContext();

  return (
    <>
      <Box
        display='flex'
        gap={4}
        alignItems='center'
        mb={4}
        justifyContent='space-between'
        flexWrap='wrap'
      >
        <Box component='label' display='flex' flexDirection='column'>
          <Box component='span' mb={0.5}>
            Ist das Angebot auch für neue Personen verfügbar?
          </Box>
          <CustomSwitch
            name='neueKlientenApi'
            title='Angebot verfügbar? rot = nein'
            data={data}
          />
        </Box>

        {/* Validierungs-Status kann erstmal nicht mehr im IB verändert werden. 
Zu viele User haben es (zu Unrecht) auf validiert gesetzt */}
        {/*         <Box
          component='label'
          display='flex'
          flexDirection='column'
          htmlFor='validierungs-status'
        >
          <Box component='span' mr={1} mb={0.5}>
            Validierung
          </Box>

          <Select
            labelId='demo-select-small'
            id='validierungs-status'
            name={'validierung'}
            value={values.validierung}
            onChange={(e) => {
              handleChange(e);
              setFieldValue('validierung', e.target.value);
            }}
            sx={{
              minWidth: '150px',
            }}
          >
            <MenuItem value={'unvalidiert'}>unvalidiert</MenuItem>
            <MenuItem value={'alles uptodate'}>alles uptodate</MenuItem>
            <MenuItem value={'updated Kleinigkeiten'}>
              updated Kleinigkeiten
            </MenuItem>
            <MenuItem value={'updated mehr'}>updated mehr</MenuItem>
            <MenuItem value={'toDo Kleinigkeiten'}>toDo Kleinigkeiten</MenuItem>
            <MenuItem value={'toDo mehr'}>toDo mehr</MenuItem>
          </Select>
        </Box> */}
      </Box>

      <Box
        sx={{
          paddingBottom: '2em',
        }}
      >
        <Typography>
          Ist das Angebot deutschlandweit verfügbar? (z.B. bei
          Online-Selbsthilfegruppen, Telefonhotline, o.ä.)
        </Typography>

        <Checkbox
          name='einsDeutschlandweit'
          checked={values['einsDeutschlandweit' as keyof FormValues]}
          onChange={(e) => {
            setFieldValue('einsDeutschlandweit', e.target.checked, true);
          }}
          sx={{
            paddingLeft: 0,
            marginRight: '.5em',
          }}
        />
        <Typography component='span'>deutschlandweit</Typography>
      </Box>

      <Box
        sx={{
          '& > * .MuiInputBase-root': { background: '#fff' },
          display: 'grid',
          gridGap: '1.6em',
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          gridTemplateRows:
            '[eins] auto [trager] auto [tragerCheck] auto [zwei] auto [drei] auto [vier] auto [fuenf] auto [sechs] auto [sieben] auto [acht]',
          mb: '2em',
          '@media (max-width:900px)': {
            // eslint-disable-line no-useless-computed-key
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        {topFormFields.map(
          ({
            name,
            label,
            type,
            gridColumnEnd,
            gridColumnStart,
            gridRowStart,
            tooltip,
            required,
          }) => {
            return (
              <Fragment key={name}>
                <Field name={name}>
                  {({ field }: { field: any }) => (
                    <InputTooltip
                      name={name}
                      tooltip={tooltip}
                      open={isTooltipOpen[name as keyof FormValues] ?? false}
                      handleOpen={() => handleOpen(name)}
                      handleClose={() => handleClose(name)}
                    >
                      <TextField
                        {...field}
                        type={type ?? 'text'}
                        label={label}
                        InputLabelProps={{
                          shrink: !!values[name as keyof FormValues],
                        }}
                        sx={{
                          gridRowStart,
                          gridColumnStart,
                          gridColumnEnd,
                        }}
                        id={name}
                        value={values[name as keyof FormValues]}
                        onChange={async (e) => {
                          field.onChange(e);
                          let value = e.target.value;
                          // prepend https to url if not there
                          if (
                            name === 'homePage' &&
                            !value.startsWith('http') &&
                            value !== ''
                          )
                            value = 'https://' + value;
                          if (name === 'plz') {
                            value = value.trim();

                            String(value).length === 5 &&
                              (await getOrt(e.target.value, setFieldValue));
                            validateField('ort');
                          }
                          setFieldValue(name, value, true);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              {(name === 'strasse' && traegerData?.strasse) ||
                              (name === 'zusatz' && traegerData?.zusatz) ||
                              (name === 'hausnummer' &&
                                traegerData?.hausnummer) ||
                              (name === 'plz' && traegerData?.plz) ||
                              (name === 'ort' && traegerData?.ort) ||
                              (name === 'email' && traegerData?.email) ||
                              (name === 'telefon' && traegerData?.telefon) ||
                              (name === 'homePage' && traegerData?.homePage) ? (
                                traegerData[name] !== '' ? (
                                  <Tooltip
                                    title={`Wert vom Traeger übernehmen`}
                                  >
                                    <IconButton
                                      onClick={async () => {
                                        setFieldValue(name, traegerData[name]);
                                        name === 'plz' &&
                                          (await getOrt(
                                            traegerData[name],
                                            setFieldValue
                                          ));
                                        validateField('ort');
                                      }}
                                    >
                                      <ContentPasteOutlined fontSize='small' />
                                    </IconButton>
                                  </Tooltip>
                                ) : null
                              ) : null}
                              {tooltip && (
                                <InfoOutlinedIcon
                                  fontSize='small'
                                  onClick={() => {
                                    handleOpen(name);
                                  }}
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                        error={
                          touched[name as keyof FormValues] &&
                          !!errors[name as keyof FormValues]
                        }
                        helperText={
                          touched[name as keyof FormValues] &&
                          errors[name as keyof FormValues]
                        }
                        required={required ?? false}
                      />
                    </InputTooltip>
                  )}
                </Field>

                {name === 'name' && traegerComponent}
                {name === 'name' && (
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    sx={{
                      marginTop: '-1rem',
                      cursor: 'pointer',
                      minWidth: 'max-content',
                    }}
                    component={'label'}
                  >
                    <Checkbox
                      name='einsKeinTraeger'
                      checked={values['einsKeinTraeger' as keyof FormValues]}
                      onChange={(e) => {
                        setFieldValue(
                          'einsKeinTraeger',
                          e.target.checked,
                          true
                        );
                      }}
                      sx={{
                        padding: '0',
                        marginRight: '.5em',
                        gridRowStart: 'tragerCheck',
                        gridColumnStart: 1,
                        gridColumnEnd: 4,
                        maxHeight: '55px',
                      }}
                    />
                    <Typography component='span'>
                      Kein Träger vorhanden
                    </Typography>
                  </Box>
                )}

                {name === 'homePage' &&
                  values.homePage &&
                  !errors['homePage'] &&
                  values.homePage !== 'http://' && (
                    <Box
                      component={'a'}
                      style={{ textDecoration: 'none' }}
                      target='_blank'
                      href={values.homePage}
                      rel='noreferrer'
                      display='flex'
                      alignContent='center'
                      justifyContent='center'
                      alignSelf='center'
                      color='primary.main'
                      border='1px solid'
                      borderColor='primary.main'
                      borderRadius='5px'
                      p={1.6}
                      sx={{
                        gridRowStart: 'sieben',
                        gridColumnStart: 4,
                        gridColumnEnd: 5,
                      }}
                    >
                      Zur Webseite
                    </Box>
                  )}
                {name === 'homePage' && (
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    sx={{
                      gridRowStart: 'sieben',
                      gridColumnStart: 3,
                      gridColumnEnd: 4,
                      marginTop: '-1.2em',
                      marginBottom: '-.2em',
                      cursor: 'pointer',
                    }}
                    component={'label'}
                  >
                    <Checkbox
                      name='isWebsiteUnavailable'
                      checked={values['isWebsiteUnavailable']}
                      onChange={(e) => {
                        setFieldValue(
                          'isWebsiteUnavailable',
                          e.target.checked,
                          true
                        );
                      }}
                      sx={{ padding: '0', marginRight: '.5em' }}
                    />
                    <Typography>
                      Webseite nicht verfügbar <br /> (z.B. weil es keine gibt)
                    </Typography>
                  </Box>
                )}
              </Fragment>
            );
          }
        )}
      </Box>
    </>
  );
}
