import { postData } from '@components/_api';
import BackBtn from '@components/BackBtn';
import EinrichtungElement from '@components/dashboard-ma/MAEinrichtung/EinrichtungElement';
import DashboardBox from '@components/DashboardBox';
import DashboardMainBox from '@components/DashboardMainBox';
import LoadingIndicator from '@components/LoadingIndicator';
import Search from '@components/search/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { Angebot } from '../../interfaces';
import { theme } from '../../theme';
import { getRoles } from '../../utils/getRoles';
import RoleSwitch from './RoleSwitch';

export default function MAEinrichtungen({ token }: { token: string }) {
  const auth = useAuth();
  const roles = getRoles(auth);
  const [data, setData] = useState<Angebot[] | []>([]);
  const [loading, setLoading] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [skip, setSkip] = useState(0);
  const [error, setError] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  const [endpoint, setEndpoint] = useState('AngeboteAuth/getuser');

  const json = {
    skip: skip,
    take: 100,
    sortOrders: [{ property: 'ort', ascending: true }],
  };

  const updateData = (data: Angebot[]) => {
    setData(data);
  };

  // load data
  function load(reset: boolean) {
    let d = data;
    if (skip > 0) {
      setLoadMore(true);
    }

    if (reset) {
      setIsSearch(false);
      json.skip = 0;
      d = [];
    }

    postData({ endpoint, json, token, apiversion: 'v2' })
      .then((resp) => {
        setData([...d, ...resp.data]);
      })
      .catch(() => {
        setData([]);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
        setLoadMore(false);
      });

    // load more
    setSkip(skip + 10);
  }

  // load initial data from API
  useEffect(() => {
    load(true);
  }, [endpoint]); // don't add load as dep

  return (
    <ThemeProvider theme={theme}>
      <DashboardMainBox>
        <Typography>Meine bisher angelegten Angebote</Typography>
        {!loading && error && <Box>Fehler</Box>}

        {!loading && !error && (
          <Box sx={{ borderColor: 'divider', marginTop: '2em' }}>
            <Search
              updateData={updateData}
              reset={load}
              setIsSearch={setIsSearch}
              token={token}
            />

            {roles.includes('MT-Scout-Admin') && (
              <RoleSwitch setEndpoint={setEndpoint} />
            )}

            {data.length > 0 &&
              data.map((einrichtung, idx) => (
                <EinrichtungElement key={idx} item={einrichtung} />
              ))}
            {data.length < 1 && (
              <DashboardBox style={{ marginTop: '2em' }}>
                <Box>Keine Ergebnisse</Box>
              </DashboardBox>
            )}

            {loading && <LoadingIndicator />}
            {loadMore && (
              <Box>
                <LinearProgress />
              </Box>
            )}

            {/* Buttons */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '2em',
                flexDirection: { xs: 'column', md: 'row' },
              }}
            >
              <BackBtn />
              {!isSearch && (
                <Button
                  color='secondary'
                  variant='contained'
                  onClick={() => load(false)}
                >
                  Mehr laden..
                </Button>
              )}
            </Box>
          </Box>
        )}
      </DashboardMainBox>
    </ThemeProvider>
  );
}
