export const fieldsConfig = [
  {
    label: 'Firma',
    name: 'firma',
    type: 'text',
  },
  {
    label: 'Straße',
    type: 'text',
    name: 'strasse',
  },
  {
    label: 'Hausnummer',
    type: 'text',
    name: 'hausnummer',
  },
  {
    label: 'Zusatz',
    type: 'text',
    name: 'zusatz',
  },
  {
    label: 'PLZ',
    type: 'text',
    name: 'plz',
  },
  {
    label: 'Ort',
    type: 'text',
    name: 'ort',
  },
  {
    label: 'Postfach PLZ',
    type: 'text',
    name: 'postfachPlz',
  },
  {
    label: 'Postfach Nummer ',
    type: 'text',
    name: 'postfachNr',
  },
  {
    label: 'Email',
    type: 'email',
    name: 'email',
  },

  {
    label: 'Telefon',
    type: 'text',
    name: 'telefon',
  },

  {
    label: 'Webseite',
    type: 'text',
    name: 'homePage',
  },

  {
    label: 'Bemerkungen',
    type: 'textarea',
    name: 'bemerkungen',
  },

  {
    type: 'textarea',
    name: 'beschreibung',
  },
];
