import axios from 'axios';

export const getOrt = async (
  plz: string,
  setFieldValue: (name: string, values: any, shouldValidate: boolean) => void
) => {
  try {
    const { data } = await axios.get(
      `https://openplzapi.org/de/Localities?postalCode=${plz}`
    );
    data[0]?.name && setFieldValue('ort', data[0].name, true);
    return;
  } catch (error) {
    console.error(error);
  }
};
