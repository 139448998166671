import React, { Suspense } from "react";

import Button from "@mui/material/Button";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../theme";
import { FormWrapper, LoginButtons, Picture, TopMenu, Wrapper } from "./Login-Styles";
import { useAuth } from "react-oidc-context";

const RegisterModal = React.lazy(() => import('@components/RegisterModal'));

export default function Login() {
  const { signinRedirect } = useAuth();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Picture />
        <FormWrapper>
          <TopMenu>
            <ul>
              <li>MUT-TOUR</li>
              <li>MUT-ATLAS</li>
              <li>Mut fördern e.V.</li>
            </ul>
          </TopMenu>

          <h1>Willkommen!</h1>
          <h2>Login oder registriere Dich neu</h2>
          <LoginButtons>
            <Button
              color='secondary'
              type='submit'
              variant='contained'
              onClick={() =>
                signinRedirect({ redirect_uri: window.location.href })
              }
            >
              Login
            </Button>
            <Button type='submit' variant='outlined' onClick={handleClickOpen}>
              Registrierung
            </Button>
          </LoginButtons>
          <Suspense fallback={<div>Loading ...</div>}>
            <RegisterModal open={open} handleClose={handleClose} />
          </Suspense>
        </FormWrapper>
      </Wrapper>
    </ThemeProvider>
  );
}
