import React from 'react'
import {theme} from '../theme'
import { ThemeProvider } from '@mui/material/styles';

const LoadingScreen = ()=>{
    return (
        <ThemeProvider theme={theme}>
            <div style={{display: 'flex', height: '70vh'}}>
              <h1 style={{display: 'flex', margin: 'auto', fontFamily: 'Roboto, sans-serif' }}>Lade ... bitte warten</h1>
            </div> 
        </ThemeProvider>
    )
}


export default LoadingScreen;