import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { FormValues } from '../MAEinrichtung';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelect({ data }: { data: string[] }) {
  const {
    palette: {
      primary: { main },
    },
  } = useTheme();

  const [openTooltip, setOpenTooltip] = React.useState(false);
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const wasDetailsValues = values['kats']?.kat_WasDetail as string[];

  return (
    <div>
      <Box>
        <Box
          component='span'
          alignItems={'center'}
          color={main}
          fontSize='1.5rem'
        >
          Unterkategorien
        </Box>
        <Tooltip
          title={
            <Typography>
              Erläuterungen zu den Kategorien findest du im MUT-ATLAS
            </Typography>
          }
          open={openTooltip}
          onClose={() => setOpenTooltip(false)}
          onOpen={() => setOpenTooltip(true)}
        >
          <IconButton onClick={() => setOpenTooltip(true)} size='small'>
            <InfoOutlinedIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      </Box>

      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id='katWas_details'>Unterkategorien</InputLabel>
        <Select
          labelId='katWas_details'
          id='katWas_details'
          multiple
          value={wasDetailsValues}
          MenuProps={MenuProps}
          input={<OutlinedInput id='select-multiple-chip' label='Was detail' />}
          renderValue={() => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {wasDetailsValues &&
                wasDetailsValues.map((value, i) => (
                  <Chip key={i} label={value} />
                ))}
            </Box>
          )}
        >
          {data?.map((value, i: number) => {
            return (
              <MenuItem
                key={i}
                value={value}
                onClick={() => {
                  const katWasFormValue = values['kats']?.kat_WasDetail || [];

                  const newValues = katWasFormValue as string[];
                  newValues.includes(value)
                    ? setFieldValue(
                        'kats.kat_WasDetail',
                        newValues.filter((item) => item !== value),
                        true
                      )
                    : setFieldValue('kats.kat_WasDetail', [
                        ...newValues,
                        value,
                      ], true);
                }}
              >
                {value}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
