import axios, { AxiosResponse } from 'axios';

export type ApiVersion = 'v1' | 'v2';

const headers = (token: string, controller?: any) => {
  return {
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    signal: controller?.signal ?? null,
  };
};

export async function getData({
  endpoint,
  token,
  apiversion,
}: {
  endpoint: string;
  token?: string;
  apiversion?: ApiVersion;
}) {
  let resp = null;
  let apiurl = process.env.REACT_APP_API_URL;
  if (apiversion === 'v2') {
    apiurl = process.env.REACT_APP_API_URL_V2;
  }
  token
    ? (resp = axios.get(apiurl + endpoint, headers(token)))
    : (resp = axios.get(apiurl + endpoint));

  resp
    .then((resp: AxiosResponse) => {
      return resp;
    })
    .catch((errors) => {
      console.error('Status: ' + errors.response.status);
      console.error('Status: ' + errors);
      return errors;
    });

  return resp;
}

export async function postData({
  endpoint,
  json,
  token = '',
  controller,
  apiversion,
}: {
  endpoint: string;
  json: Record<string, any>;
  token: string;
  controller?: any;
  apiversion?: ApiVersion;
}) {
  const payload = JSON.stringify(json);
  let apiurl = process.env.REACT_APP_API_URL;
  if (apiversion === 'v2') {
    apiurl = process.env.REACT_APP_API_URL_V2;
  }

  const resp = axios
    .post(apiurl + endpoint, payload, headers(token, controller))
    .then((resp) => {
      // console.log( resp )
      if (resp.status === 200) {
        return resp;
      } else if (resp.status === 204) {
        // No data!
        return 'no data';
      } else if (resp.status === 400) {
        return 'validation error';
      } else if (resp.status === 500) {
        return 'Server error';
      }
    })
    .catch(function (error) {
      return error.toJSON();
    });

  return await resp;
}
